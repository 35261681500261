<template>
    <div>
        <section class='trbaner'>
            <div class="foxj">
                <div class='am-container'>
                    <div class='txt'>
                        <div class="am-vertical-align-middle">
                            <p class="tit">智慧水务·数字水务综合运营服务商</p>
                            <p class="note">以客户服务为导向，通过智能物联设备、数据赋能平台及智慧运营管控服务，为水务行业上、下游客户提供全流程解决方案。</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src='../assets/banner/banner3.png'>
        </section>
        <section class="am-path">
            <div class="am-container">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item> <a @click="getList()">方案介绍</a></el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </section>
        <section>
            <div class="am-container">
                <div class="ny">
                    <div class="nyleft">
                        <el-col>
                            <p class="tit">解决方案</p>
                            <el-menu default-active="2" class="el-menu-vertical-demo">
                                <template>
                                    <el-menu-item v-for="item in dicList.solution_class" :key="item.dic_code"
                                        style="color:#000" @click="router(item)">
                                        {{ item.dic_name }}
                                    </el-menu-item>
                                </template>
                            </el-menu>
                        </el-col>
                    </div>
                    <div class="nyright">
                        <div class="ptys-list">
                            <img :src="soluimg" style="width: 100%;height: 500px;border: 1px solid #f5f5f5;">
                            <ul class="am-avg-md-3" style="height: 685px;overflow: auto;">
                                <li v-for="(item, index) in dataList" :key='index'>
                                    <a @click="func(item)">
                                        <i><img :src="/zhcwaterweb/ + item.furl"></i>
                                        <div class="txt">
                                            <p class="tit">{{ item.solution_name }}</p>
                                            <p class="note">{{ item.solution_blurb }}</p>
                                        </div>
                                    </a>
                                </li>
                                <div class="pagination" style="width: 98%;">
                                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                        :page-size="10" layout=" prev, pager, next" :total="total">
                                    </el-pagination>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="homed">
            <p>有疑问或想了解更多，请咨询：{{this.$parent.data.company_tel}}</p>
        </section>-->
    </div>
</template>
<script>
import {
    getList
} from 'api/GetList'
import { getDataDicList } from 'api/datadicmanage'
export default {
    data() {
        return {
            soluimg: require('../assets/images/solition/水系综合管控平台.jpg'),
            dataList: [],
            dicList: {
                solution_class:[]
            },
            //列表数据
            modelForm: [],
            data: [],
            searchInfo: {
                solution_class: '',
                page_no: 1,
                page_size: 6,
            },
            total: 1
        }
    },
    methods: {
        getList() {
            this.isShowloading = true;
            getList(this.searchInfo).then((res) => {
                this.total = res.data.entity.all_count
                this.dataList = res.data.list;
            });
        },
        router(val) {
            console.log(val)
            if (val.dic_code == '01') {
                this.soluimg = require('../assets/images/solition/水系综合管控平台.jpg')
            } else if (val.dic_code == '02') {
                this.soluimg = require('../assets/images/solition/客户营销服务一体化解决方案.jpg')
            } else if (val.dic_code == '03') {
                this.soluimg = require('../assets/images/solition/生产经营一体化解决方案.jpg')
            } else if (val.dic_code == '04') {
                this.soluimg = require('../assets/images/solition/大数据一体化解决方案.jpg')
            } else if (val.dic_code == '05') {
                this.soluimg = require('../assets/images/solition/智能终端解决方案.jpg')
            } else if (val.dic_code == '06') {
                this.soluimg = require('../assets/images/solition/智慧污水管理系统解决方案.jpg')
            }
            this.searchInfo.solution_class = val.dic_code
            this.searchInfo.page_no = 1
            this.searchInfo.page_size = 6
            this.getList()
        },
        func(item) {
            let pathInfo = this.$router.resolve({
                path: '/views/solution1',
                query: {
                    id: item.fuid
                }
            })
            window.open(pathInfo.href, '_blank')
            // this.$router.push({name: 'solution1',params:{ id:item.fuid}});
        },
        handleSizeChange(val) {
            this.searchInfo.page_no = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.searchInfo.page_no = val
            this.getList()

        },
        //获取字典
        geidic() {
            getDataDicList({
                parent_code: "solution_class",
            }).then((res) => {
                this.dicList.solution_class = res.data.list;
            });//票据类型
        },

    },
    mounted() {
        this.getList()
        this.geidic()
    }
}
</script>
<style lang="scss" scoped>
.trbaner {
    position: relative;
}

.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}

.trbaner .foxj {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}

.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}

.foxj .txt {
    position: absolute;
    text-align: left;
    left: 1.5rem;
    height: 100%;
    display: table-cell;
    width: 46%;
}

.foxj .txt .tit {
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}

.foxj .note {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}

.am-container::after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.foxj .txt .am-vertical-align-middle {
    position: relative;
    top: 30%;
}

.am-path {
    height: 50px;

    .el-breadcrumb {
        border: 1px solid #eee;
        font-size: 14px;
        line-height: 50px;
        height: 50px;
        padding-left: 20px;
    }
}

.ny {
    display: flex;
    width: 100%;

    .nyright {
        width: 80%;
    }

    .nyleft {
        width: 19%;
        height: 100%;
        margin-top: 30px;

        .tit {
            font-size: 22px;
            padding: 15px 0 15px 25px;
            background: #00579d;
            color: #fff;
        }

        .el-submenu {
            background-color: #f5f5f5;
            margin: 6px 10px;
        }
    }
}

.ptys-list {
    padding: 30px;

    ul {
        display: -webkit-flex;
        display: flex;
        align-content: flex-start;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -15px;

        a {
            background-color: #f5f5f5;
            display: block;
        }

        a:hover {
            img {
                transform: scale(1.2);
            }
        }

        li {
            padding: 15px;
            list-style: none;
            width: 46%;
            display: block;
            text-align: center;

            i {
                display: block;
                float: left;
                width: 46%;
                overflow: hidden;

                img {
                    width: 100%;
                    transition: all .7s;
                    height: 182px;
                }

            }

            .txt {
                padding: 15px;
                text-align: left;
                list-style: none;
                height: 152px;
                overflow: hidden;

                // box-shadow: 0 1px 6px rgba(0,0,0,.1);
                .tit {
                    font-size: 20px;
                    margin-top: 5px;
                    margin-bottom: 8px;
                }

                .note {
                    font-size: 14px;
                    color: #999;
                    min-height: 110px;
                }
            }
        }
    }

}

.homed {
    text-align: center;
    font-size: 24px;
    color: #fff;
    padding: 3% 0;
    background: url('~images/adbg.jpg') center no-repeat;
    background-size: cover;
}

// .pagination {

// }</style>

<style lang="sass">
.breadcrumb
</style>